/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: 'UA-174238252-2',
};

//Home Page
const greeting = {
  title: 'Hello 👋.',
  title2: 'Taha',
  logo_name: 'taha-k()',
  nickname: 'harry / picleric',
  full_name: 'Taha Kassar',
  subTitle: 'Full Stack Developer, Open Source Enthusiast 🔥. Always learning.',
  resumeLink: 'https://taha-kassar.atconcept.tech/CV.pdf',
  mail: 'mailto:tahakassar54@gmail.com',
};

const socialMediaLinks = {
  /* Your Social Media Link */
  // github: "https://github.com/harikanani",
  linkedin: 'https://www.linkedin.com/in/taha-kassar97',
  gmail: 'tahakassar54@gmail.com',
  gitlab: 'https://gitlab.com/tahakassar97',
  // facebook: "https://www.facebook.com/harikrishan.kanani/",
  // twitter: "https://twitter.com/Harikrushn9",
  // instagram: "https://www.instagram.com/hari_kanani_/",
};

const skills = {
  data: [
    {
      title: 'Full Stack Development',
      fileName: 'FullStackImg',
      skills: [
        '⚡ Develop highly interactive Front end / User Interfaces for your website',
        '⚡ Building responsive website front end using ReactJS',
        '⚡ Creating application backend in Node, Express',
        '⚡ Integration of third party services such as Firebase',
      ],
      softwareSkills: [
        {
          skillName: 'HTML5',
          fontAwesomeClassname: 'simple-icons:html5',
          style: {
            color: '#E34F26',
          },
        },
        {
          skillName: 'CSS3',
          fontAwesomeClassname: 'fa-css3',
          style: {
            color: '#1572B6',
          },
        },
        {
          skillName: 'JavaScript',
          fontAwesomeClassname: 'simple-icons:javascript',
          style: {
            backgroundColor: '#FFFFFF',
            color: '#F7DF1E',
          },
        },
        {
          skillName: 'TypeScript',
          fontAwesomeClassname: 'simple-icons:typescript',
          style: {
            color: '#1572B6',
          },
        },
        {
          skillName: 'ReactJS',
          fontAwesomeClassname: 'simple-icons:react',
          style: {
            color: '#61DAFB',
          },
        },
        {
          skillName: 'NodeJS',
          fontAwesomeClassname: 'simple-icons:node-dot-js',
          style: {
            color: '#339933',
          },
        },
        {
          skillName: 'NPM',
          fontAwesomeClassname: 'simple-icons:npm',
          style: {
            color: '#CB3837',
          },
        },
        {
          skillName: 'Yarn',
          fontAwesomeClassname: 'simple-icons:yarn',
          style: {
            color: '#1572B6',
          },
        },
        {
          skillName: 'Nx',
          fontAwesomeClassname: 'simple-icons:nx',
          style: {
            color: '#4099CD',
          },
        },
        {
          skillName: 'Tailwind',
          fontAwesomeClassname: 'simple-icons:tailwindcss',
          style: {
            color: 'rgb(68, 168, 179)',
          },
        },
        {
          skillName: 'Material UI',
          fontAwesomeClassname: 'simple-icons:mui',
          style: {
            color: 'rgb(68, 168, 179)',
          },
        },
        {
          skillName: 'MongoDB',
          fontAwesomeClassname: 'simple-icons:mongodb',
          style: {
            color: '#439743',
          },
        },
        {
          skillName: 'GraphQL',
          fontAwesomeClassname: 'simple-icons:graphql',
          style: {
            color: '#DE33A6',
          },
        },
        {
          skillName: 'Java',
          fontAwesomeClassname: 'simple-icons:java',
          style: {
            color: '#f89820',
          },
        },
        {
          skillName: 'MySQL',
          fontAwesomeClassname: 'simple-icons:mysql',
          style: {
            color: '#4479A1',
          },
        },
        {
          skillName: 'Socket IO',
          fontAwesomeClassname: 'simple-icons:socketdotio',
          style: {
            color: '#FFFFFF',
          },
        },
        {
          skillName: 'Git',
          fontAwesomeClassname: 'simple-icons:git',
          style: {
            color: '#E94E32',
          },
        },
      ],
    },
    // {
    //   title: 'Cloud Infra-Architecture',
    //   fileName: 'CloudInfraImg',
    //   skills: [
    //     '⚡ Experience working on multiple cloud platforms',
    //     '⚡ Experience hosting and managing websites',
    //     '⚡ Experience with Continuous Integration',
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: 'AWS',
    //       fontAwesomeClassname: 'simple-icons:amazonaws',
    //       style: {
    //         color: '#FF9900',
    //       },
    //     },
    //     {
    //       skillName: 'Netlify',
    //       fontAwesomeClassname: 'simple-icons:netlify',
    //       style: {
    //         color: '#38AFBB',
    //       },
    //     },
    //     {
    //       skillName: 'Heroku',
    //       fontAwesomeClassname: 'simple-icons:heroku',
    //       style: {
    //         color: '#6863A6',
    //       },
    //     },
    //     {
    //       skillName: 'Firebase',
    //       fontAwesomeClassname: 'simple-icons:firebase',
    //       style: {
    //         color: '#FFCA28',
    //       },
    //     },
    //     {
    //       skillName: 'PostgreSQL',
    //       fontAwesomeClassname: 'simple-icons:postgresql',
    //       style: {
    //         color: '#336791',
    //       },
    //     },
    //     {
    //       skillName: 'MongoDB',
    //       fontAwesomeClassname: 'simple-icons:mongodb',
    //       style: {
    //         color: '#47A248',
    //       },
    //     },
    //     {
    //       skillName: 'Docker',
    //       fontAwesomeClassname: 'simple-icons:docker',
    //       style: {
    //         color: '#1488C6',
    //       },
    //     },
    //     {
    //       skillName: 'GitHub Actions',
    //       fontAwesomeClassname: 'simple-icons:githubactions',
    //       style: {
    //         color: '#5b77ef',
    //       },
    //     },
    //   ],
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: 'Tishreen University',
      subtitle: 'Bachelor in Information Technology',
      logo_path: 'ssec.png',
      alt_name: 'TU',
      duration: '2015 - 2020',
      descriptions: [
        '⚡ I graduated with bachelors degree in Information Technology.',
        '⚡ I studied core subjects like Algorithms and Data Structures, DBMS, Networking, Security, etc.',
        '⚡ Analyzed and developed a diverse project using multiple programming languages and frameworks.   Demonstrated problem-solving, teamwork, and communication skills. Successfully presented and defended the project.',
      ],
      website_link: 'https://tishreen.edu.sy/',
      certificate_link:
        'https://drive.google.com/file/d/15fidVIZR0wNIVy2Hx9Kf3ABMd_VEQNqV/view?usp=share_link',
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: 'Tamayuz Contest',
      subtitle: 'First Place',
      logo_path: 'tamayuzLogo.png',
      alt_name: 'Tamayuz Contest',
      duration: '2020',
      descriptions: [
        '⚡ I got first place in Tamayuz Contest, by participated in a technical project.',
        '⚡ The contest was in three levels, in the last level, we got first place.',
      ],
      website_link: 'https://www.facebook.com/tamayuz.cpt/',
      certificate_link:
        'https://drive.google.com/file/d/13A_dzHi5AzmyRlchDoffUdsEwOj6Vkdk/view?usp=share_link',
    },
  ],
};

// const certifications = {
//   // certifications: [
//   //   {
//   //     title: "M0001: MongoDB Basics",
//   //     subtitle: "MongoDB University",
//   //     logo_path: "mongo.png",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
//   //     alt_name: "MongoDB University",
//   //     // color_code: "#2AAFED",
//   //     color_code: "#47A048",
//   //   },
//   //   {
//   //     title: "Hackathon",
//   //     subtitle: "Hack The Mountains",
//   //     logo_path: "hackathon1.svg",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
//   //     alt_name: "hackathon",
//   //     color_code: "#E2405F",
//   //   },
//   //   {
//   //     title: "A300: Atlas Security",
//   //     subtitle: "MongoDB University",
//   //     logo_path: "mongo.png",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
//   //     alt_name: "MongoDB University",
//   //     // color_code: "#F6B808",
//   //     color_code: "#47A048",
//   //   },
//   //   {
//   //     title: "Cyber Security & Cyber Forensics",
//   //     subtitle: "Workshop at IIT Bombay",
//   //     logo_path: "iit.png",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
//   //     alt_name: "Workshop",
//   //     color_code: "#2AAFED",
//   //   },
//   //   {
//   //     title: "MLH Local Hack Day: Build",
//   //     subtitle: "Major League Hacking",
//   //     logo_path: "mlh-logo.svg",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
//   //     alt_name: "Google",
//   //     color_code: "#fe0037",
//   //   },
//   //   {
//   //     title: "Hack20",
//   //     subtitle: "Flutter International Hackathon",
//   //     logo_path: "flutter.png",
//   //     certificate_link:
//   //       "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
//   //     alt_name: "Flutter International Hackathon",
//   //     color_code: "#2AAFED",
//   //   },
//   //   {
//   //     title: "Postman Student Expert",
//   //     subtitle: "Postman",
//   //     logo_path: "postman.png",
//   //     certificate_link:
//   //       "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
//   //     alt_name: "Postman",
//   //     // color_code: "#f36c3d",
//   //     color_code: "#fffbf3",
//   //   },
//     // color_code: "#8C151599",
//     // color_code: "#7A7A7A",
//     // color_code: "#0C9D5899",
//     // color_code: "#C5E2EE",
//     // color_code: "#ffc475",
//     // color_code: "#g",
//     // color_code: "#ffbfae",
//     // color_code: "#fffbf3",
//     // color_code: "#b190b0",
//   // ],
// };

// Experience Page
const experience = {
  title: 'Experience',
  subtitle: 'Work and Volunteership',
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: 'experience.svg',
  sections: [
    {
      title: 'Work Experience',
      experiences: [
        {
          title: 'Senior Frontend Developer',
          company: 'CipherBizz',
          company_url: 'https://www.cipherbizz.com/',
          logo_path: 'cipherbizz.png',
          duration: 'Sep 2023 - Present',
          location: 'Dubai, United Arab Emirates',
          description: `
          Enhance user interface, implementing a comprehensive overhaul to elevate visual aesthetics and functionality,
          The implemented enhancements resulted in a notable increase in stakeholder satisfaction, measured through
          user feedback and engagement metrics, affirming the substantial positive impact on the overall project goals.
          -
          Implemented HTTP-only cookie integration for multiple domains to bolster security measures.
          -
          Introduced refresh token logic, enhancing security within the legacy ReactJS application.
          -
          Implemented a versatile letter generation module, empowering HR personnel to create and generate letters
          using customizable created templates, thereby optimizing communication processes.
          -
          Upgraded the system to support multi-timezones, addressing legacy issues by aligning the backend in UTC
          while enabling frontend handling of various timezones, enhancing system flexibility.
          -
          Implemented significant enhancements to the code structure, seamlessly integrating frontend logic with the
          backend, Utilized React Query to streamline and optimize data fetching, caching, and state management,
          resulting in a more efficient and responsive UI.
          -
          Introducing multilingual support for Right to Left. The seamless switch feature allows users to toggle between
          languages (Arabic - English) contributing to increased satisfaction among stakeholders.
`,
          color: '#0071C5',
        },
        {
          title: 'Frontend Developer',
          company: 'Divcodes',
          company_url: 'https://divcodes.com/',
          logo_path: 'valora.jpg',
          duration: 'May 2021 - Sept 2023',
          location: 'Beirut, Lebanon',
          description: `
          Collaborate with business analysts to refine software requirements, ensuring a clear understanding of project
goals and user needs.
-
Architect a core with reusable components to create dashboards, resulting in a 30% improvement in code
reusability and accelerated development speed.
-
Leverage the Next.js framework to optimize web development processes and enhance SEO capabilities,
leading to improved website performance and a 20% increase in organic search traffic.
-
Develop and implement highly-responsive user interface components using React concepts.
-
Implement RESTful API and GraphQL integration, reducing API response time by 50% and enhancing data
retrieval efficiency through caching.
-
Mentor and guide junior team members, fostering a collaborative and growth-oriented environment.
-
Successfully adopted the Agile methodology for software workflow management, resulting in an 80%
reduction in development cycle time, increased team collaboration, and on-time project deliveries.
-
Leverage Socket.io, Firebase, Google Maps services, and Payment gateway integration to enhance application
functionality, resulting in a 40% increase in user retention and overall customer satisfaction.
-
Master Nx monorepo, adopted an efficient micro frontend architecture, streamlining development workflows
and leading to a 20% improvement in development.
-
Develop advanced and reusable hooks, effectively reducing code duplication by 40% and improving
development speed, resulting in a 25% decrease in bug reports and faster feature implementations.
-
Utilize Canva's JavaScript libraries (Three.js & Konva.js) to develop an advanced online reservation system.
`,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: '#0071C5',
        },
        {
          title: 'Full Stack Developer (Freelancer)',
          company: '@Concept Tech',
          company_url: 'https://atcontept.tech/',
          logo_path: 'valora.jpg',
          duration: 'January 2021 - July 2022',
          location: 'Work From Home',
          description: `Designed, developed, and hosted a comprehensive system for an Educational Institute, delivering a tailored
          solution that met their specific requirements and resulted in increased operational efficiency and user
          satisfaction.
          -
          Understand business requirements and translate them into technical requirements.
          -
          Collaborated closely with the client to plan system functions, ensuring alignment with project goals.
          -
          Leveraged the MERN (MongoDB, Express.js, React.js, Node.js) stack to develop robust and scalable web
          applications, providing seamless user experiences and efficient data management.
          -
          Developed mobile applications using the Android framework, implementing intuitive and feature-rich interfaces
          that enhanced user engagement and satisfaction, resulting in a 20% increase in user retention.
          -
          Implemented efficient practices to improve development processes, such as modular programming, code
          reuse, and automated testing, resulting in increased productivity and code quality.
          `,
          // "50% of my work was doing frontend development, 30% of my work was designing and development database schemas and backend, and 20% of my work was on maintain hosting and user traffics on the website.",
          color: '#ee3c26',
        },
        {
          title: 'Coach & Teacher',
          company: 'Syrian Computer Society (SCS)',
          company_url: 'https://www.facebook.com/scs.latakia/',
          logo_path: 'valora.jpg',
          duration: 'September 2020 - October 2022',
          location: 'Lattakia, Syria',
          description: `Taught and coached individuals in a variety of programming languages and web frameworks, including
          Java, JavaScript, and ReactJS.
          -          
          Developed comprehensive curriculum and learning materials to effectively guide students in their
          programming journey.
          -
          Designed and delivered interactive and engaging programming courses, catering to diverse learning styles
          and skill levels.
          -
          Provided personalized coaching and mentoring to students, offering support and guidance in
          understanding complex programming concepts.
          -
          Fostered a collaborative learning environment, encouraging teamwork and problem-solving skills through
          group projects and coding challenges.
          -
          Stayed updated with the latest advancements and trends in programming languages and web frameworks,
          incorporating relevant updates into teaching materials.
          -
          Received positive feedback from students, reflecting the ability to deliver engaging and impactful
          instruction. `,
          color: '#0071C5',
        },
      ],
    },
    {
      title: 'Volunteerships',
      experiences: [
        {
          title: 'Volunteer and Speaker',
          company: 'Syrian Computer Society (SCS)',
          company_url: 'https://www.facebook.com/scs.latakia/',
          logo_path: 'scs.jpg',
          duration: 'September 2020 - October 2022',
          location: 'Lattakia, Syria',
          description:
            'I participated in organize of many technical events, as a speaker and a volunteer.',
          color: '#FBBD18',
        },
        {
          title: 'Volunteer and Speaker',
          company: 'Tishreen University',
          website_link: 'https://tishreen.edu.sy/',
          logo_path: 'tishreen.jfif',
          duration: 'September 2020 - July 2021',
          location: 'Lattakia, Syria',
          description:
            'I participated in a lot of technical events, as a speaker.',
          color: '#FBBD18',
        },
        // {
        //   title: "Cross Winter of Code Mentor",
        //   company: "CWOC",
        //   company_url: "https://crosswoc.ieeedtu.in/",
        //   logo_path: "cwoc.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
        //   color: "#4285F4",
        // },
        // {
        //   title: "Campus Hustler",
        //   company: "Skillenza",
        //   company_url: "https://skillenza.com/",
        //   logo_path: "skillenza.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work from Home",
        //   description:
        //     "Spread Awareness of new Technologies and new Opportunities to Students and Grow Skillenza Community.",
        //   color: "#196acf",
        // },
        // {
        //   title: "GDG Student Volunteer",
        //   company: "Google Developer Groups",
        //   company_url: "https://gdg.community.dev/",
        //   logo_path: "gdg.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Google Developer Group Surat Student Volunteer and Member.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "Postman Student Expert",
        //   company: "Postman",
        //   company_url: "https://www.postman.com/",
        //   logo_path: "postman.png",
        //   duration: "Feb 2020 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Google Developer Group Surat Student Volunteer and Member.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "GitHub Student Developer",
        //   company: "GitHub",
        //   company_url: "https://github.com/",
        //   logo_path: "github.png",
        //   duration: "Nov 2019 - Present",
        //   location: "Work from Home",
        //   description:
        //     "Contribute to Open Source Community and Open Source Project.",
        //   color: "#040f26",
        // },
        // {
        //   title: "Google Local Guide",
        //   company: "Google Map",
        //   company_url: "https://maps.google.com/localguides/",
        //   logo_path: "localguide.png",
        //   duration: "Sep 2018 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "E. F. I. Student Volunteer",
        //   company: "ENVIRONMENTALIST FOUNDATION OF INDIA",
        //   company_url: "https://indiaenvironment.org/",
        //   logo_path: "efi.png",
        //   duration: "Apr 2017 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
        //   color: "#5a900f",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: 'Projects',
  description:
    'My projects make use of a vast variety of latest technology tools. My best experience is to create React Project and NodeJS Backend Projects.',
  avatar_image_path: 'projects_image.svg',
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: 'Contact Me',
    profile_image_path: '.jpg',
    description:
      'You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ',
  },
  blogSection: {
    title: 'Blogs',
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on LinkedIn.",
    link: 'https://www.linkedin.com/in/taha-kassar97',
    avatar_image_path: 'blogs_image.svg',
  },
};

const projects = {
  data: [
    {
      id: '0',
      name: 'Point of Sale (POS)',
      // repo: 'https://gitlab.com/divcodes2/point-of-sale',
      description: `A web app to manage point of sale for food store.
          Manage Cashiers Sessions, Carts, Saved Carts, Customers, Orders and Promotions.
          The Project was wrapped with ElectronJS framework to enable setup as local application.`,
      date: 'October - 2021',
      languages: [
        {
          name: 'JavaScript',
          iconifyClass: 'logos-javascript',
        },
        {
          name: 'React JS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
      ],
    },
    {
      id: '1',
      name: 'The Whole Sale Store',
      repo: 'https://gitlab.com/divcodes2/pro-sale-store',
      website: 'http://54.167.18.195/',
      description: `A web E-commerce app. Manage Cart, Addresses, Orders etc...
        There are two types of users, Register as a Store or as an Individual.
        The Main difference between users is product prices.
        We have added stripe payment gateway integration [in test mode].`,
      date: 'December - 2021',
      languages: [
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Next JS',
          iconifyClass: 'logos:nextjs-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
      ],
    },
    {
      id: '13',
      name: 'Pixi E-commerce Store',
      // repo: 'https://gitlab.com/divcodes2/pixi-store',
      description: `A web E-commerce app. Manage Cart, Wishlist and Products attributes management. Live chat, live Order Tracking and support RTL in the app. The app was built with new technologies such as GraphQL and Nx build tool.`,
      date: 'March - 2022',
      languages: [
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Next JS',
          iconifyClass: 'logos:nextjs-icon',
        },
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'GraphQL',
          iconifyClass: 'logos-graphql',
        },
        {
          name: 'Nx',
          iconifyClass: 'logos-nx',
        },
      ],
    },
    {
      id: '2',
      name: 'Admin Dashboard Core',
      // repo: 'https://gitlab.com/divcodes2/ordro-admin-dashboard',
      description: `An admin dashboard core, with very flex components to manage and create any admin dashboard. The core was integrated via RestFull API with the backend.`,
      date: 'September - 2022',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'Nx',
          iconifyClass: 'logos-nx',
        },
      ],
    },
    {
      id: '3',
      name: 'OrdrO Admin Dashboard',
      website: 'https://dev.ordro.app',
      // repo: 'https://gitlab.com/divcodes2/ordro-admin-dashboard',
      description: `An admin dashboard for B2B E-commerce application that managed Assortments, Buyers, Sellers, Branches, Orders, FLEX promotions, advertisements, Reports. We used Google Maps Services such as Best Routes between two points and Distance Matrix. We used Metabase to display reports as Charts. We built notifications system via integration with Firebase - Live Chat system via Socket.IO. Support RTL.`,
      date: 'November - 2022',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'Google Maps',
          iconifyClass: 'logos:google-maps',
        },
        {
          name: 'Nx',
          iconifyClass: 'logos-nx',
        },
        {
          name: 'Firebase',
          iconifyClass: 'logos:firebase',
        },
        {
          name: 'SocketIO',
          iconifyClass: 'simple-icons:socketdotio',
        },
      ],
    },
    {
      id: '7',
      name: 'AlSabbagh Institute Educational',
      website: 'http://alsabbagh.atconcept.tech/#/',
      mobile:
        'https://play.google.com/store/apps/details?id=com.techConcept.alsabbagh_institute&pli=1',
      repo: 'https://gitlab.com/groups/alsabbagh',
      description: `I designed, developed, tested and hosted a system for an educational institute. The system enabled the students via a mobile application register in the institute, select sections, contact with institute admin, manage quizzes, manage courses program, receive notifications, and see Institute Stories The mobile app developed via Java and Android framework. Dashboard Panel for admin, that enable the Admin of manage classes, instructors, courses, students, quizzes, stories, and monitoring students attendance. I built backend system via NodeJS Express framework and MySQL database.`,
      date: 'January - 2022',
      languages: [
        {
          name: 'NodeJS',
          iconifyClass: 'logos-nodejs',
        },
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'MaterialUI',
          iconifyClass: 'logos-material-ui',
        },
        {
          name: 'Java',
          iconifyClass: 'logos-java',
        },
        {
          name: 'Android Framework',
          iconifyClass: 'logos:android-icon',
        },
      ],
    },
    {
      id: '20',
      name: 'Wasama (Tracking Representatives)',
      repo: 'https://gitlab.com/react-js-/wasama-sellersapp',
      description: `A web application to manage representatives visitors and routes.
      The App was integrated with firebase for live tracking representatives routes and save the routes.
      The representative start his work daily from the app, show the tasks that assigned to him and he can record, upload reports and images about his visits.
      The visit cannot start only when the representative is nearby the customer location.
      Also the representative can add the customers.
      I Used Mapbox Maps to manage the visits.`,
      date: 'June - 2021',
      languages: [
        {
          name: 'JavaScript',
          iconifyClass: 'logos-javascript',
        },
        {
          name: 'React JS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'MaterialUI',
          iconifyClass: 'logos-material-ui',
        },
        {
          name: 'Firebase',
          iconifyClass: 'logos:firebase',
        },
      ],
    },
    {
      id: '4',
      name: 'Spantrek',
      url: 'https://github.com/harikanani/personal-portfolio',
      description: `A Workplace Institution Management, to Manage workplace system (Devices, Channels, Locations, FloorPlans, Reservations ...etc). I built a floor plan 2D drawer via konva-js, to draw and display the Institution resources (Tables, Chairs, Rooms, Amenities ...) and reserve the resources via floor stage.
      The App provides integration the resources with Google Calendar, Microsoft Calendar and Cisco Spaces.
      It allows businesses to deliver targeted and engaging content to their employees, customers, or visitors through various digital channels. The platform offers features such as content creation and management, scheduling, analytics, and integration with other business systems.
      Spantrek provides a user-friendly interface that allows users to create and design multimedia content such as images, videos, slideshows, webpages, and interactive applications.
      `,
      date: 'Aug 2023',
      website: 'https://cloud.spantrek.com',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'GraphQL',
          iconifyClass: 'logos-graphql',
        },
        {
          name: 'Nx',
          iconifyClass: 'logos-nx',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
    {
      id: '30',
      name: 'CipherBizz HR Portal',
      // repo: 'https://gitlab.com/tahakassar97/dayparting-manager',
      noScreen: true,
      website: 'https://app.cipherbizz.com/',
      description: `Manage Payroll, Attendance, with flexible assign time shifts.
      Build a versatile letter generation engine, empowering HR personnel to create and generate letters
using customizable created templates, with flexible designs, thereby optimizing communication processes.
Implement Report analysis module.`,
      date: 'Sep 2023',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'Firebase',
          iconifyClass: 'logos:firebase',
        },
        {
          name: 'Pusher',
          iconifyClass: 'simple-icons:pusher',
        },
        {
          name: 'Google Maps',
          iconifyClass: 'logos:google-maps',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
    {
      id: '31',
      name: 'CipherBizz Employee Portal',
      // repo: 'https://gitlab.com/tahakassar97/dayparting-manager',
      noScreen: true,
      website: 'https://employees.cipherbizz.com/',
      description: `Web application allows the employee Clock In/ Clock Out, apply leaves, take actions based on roles, apply for a letter.
      Build Performance Management System for the employees`,
      date: 'Sep 2023',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Pusher',
          iconifyClass: 'simple-icons:pusher',
        },
        {
          name: 'MaterialUI',
          iconifyClass: 'logos-material-ui',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
    {
      id: '25',
      name: 'Simple Todo List',
      repo: 'https://gitlab.com/tahakassar97/todo-list',
      noScreen: true,
      description: ` I've created this app to showcase my coding skills.

      Simple Todo-List using React JS, Vite build tool and Tailwind CSS.
      
      Integrated with json-server to manage todos CRUD.`,
      date: 'May 2023',
      // website: 'https://todo-list.taha-kassar.atconcept.tech',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'Tailwind',
          iconifyClass: 'logos-tailwindcss-icon',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
    {
      id: '26',
      name: 'Custom useModal Hook',
      repo: 'https://gitlab.com/tahakassar97/react-use-modal',
      noScreen: true,
      description: `
      useModal is a customizable React hook that simplifies the management of modals in your React applications. It provides a convenient way to handle modal visibility, open/close actions, and scroll locking.
      Easily toggle the visibility of a modal with open, close, and toggle functions.
      Option to lock the page scroll when the modal is open.
      Customizable modal wrapper component with flexible styling options.
      Smooth animation transitions for opening and closing the modal.
      Ability to customize the overlay and handle click events outside the modal.
      Lightweight and dependency-free.`,
      date: 'June 2023',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'CSS',
          iconifyClass: 'vscode-icons:file-type-css',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
    {
      id: '27',
      name: 'DayParting',
      repo: 'https://gitlab.com/tahakassar97/dayparting-manager',
      noScreen: true,
      description: `The DayParting is a React component that allows users to set up day parting by selecting specific hours of the day. It provides an interactive table interface where users can toggle the selected hours for each day.`,
      date: 'June 2023',
      languages: [
        {
          name: 'ReactJS',
          iconifyClass: 'logos-react',
        },
        {
          name: 'TypeScript',
          iconifyClass: 'logos-typescript-icon',
        },
        {
          name: 'CSS',
          iconifyClass: 'vscode-icons:file-type-css',
        },
        {
          name: 'Vite',
          iconifyClass: 'logos:vitejs',
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
