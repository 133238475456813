import React from "react";
import "./Certifications.css";
import { Fade } from "react-reveal";
import { certifications } from "../../portfolio";
import CertificationCard from "../../components/certificationCard/CertificationCard";
import DegreeCard from "../../components/degreeCard/DegreeCard";

function Certifications(props) {
  const theme = props.theme;
  return (
    <div className="main" id="">
      <div className="educations-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="educations-header" style={{ color: theme.text }}>
            Certification
          </h1>
        </Fade>
      </div>
      <div className="educations-body-div">
        {certifications.certifications.map((degree) => {
          return <DegreeCard degree={degree} theme={theme} />;
        })}
      </div>
    </div>
  );
}

export default Certifications;
