import React, { useState } from "react";
import Main from "./containers/Main";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
import { CursorProvider } from "react-cursor-custom";
import { settings } from "./portfolio";
// import useId from './hooks/use-id';

function App() {
  // const id = useId();
  // const data = localStorage.getItem('data');

  // const browserData = navigator.userAgent;

  // useEffect(() => {
  //   if (!data) {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         function (position) {
  //           const latitude = position.coords.latitude;
  //           const longitude = position.coords.longitude;

  //           fetch(
  //             `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
  //           )
  //             .then((response) => response.json())
  //             .then((data) => {
  //               const country = data.countryName;

  //               const _data = {
  //                 id,
  //                 country,
  //                 visits: 1,
  //                 browserData,
  //               };

  //               localStorage.setItem(
  //                 'data',
  //                 JSON.stringify({
  //                   ..._data,
  //                 })
  //               );
  //             });
  //         },
  //         function (error) {
  //           console.log(error);
  //         }
  //       );
  //     }
  //   } else {
  //     const _data = localStorage.getItem('data');

  //     const data_ = JSON.parse(_data);

  //     if (data_) {
  //       localStorage.setItem('data', {
  //         ...data_,
  //         visits: ++data_.visits,
  //       });
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const useCursor = settings.useCustomCursor;

  return (
    <ThemeProvider theme={themes[theme]}>
      <>
        <GlobalStyles />
        <div>
          {useCursor ? (
            <CursorProvider
              color={themes[theme].secondaryText}
              ringSize={25}
              transitionTime={75}
            >
              <Main theme={themes[theme]} setTheme={setTheme} />
            </CursorProvider>
          ) : (
            <Main theme={themes[theme]} setTheme={setTheme} />
          )}
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
