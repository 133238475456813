import React from "react";
import "./FeelingProud.css";
import { useProgressiveImg } from "../../hooks";

function FeelingProud(props) {
  const theme = props.theme;

  const [src, { blur }] = useProgressiveImg("tiny-me.png", "me.png");

  return (
    <div style={{ position: "relative" }} className="svg">
      <svg
        version="1.1"
        viewBox="80 -10 100.1879 500"
        height="650.68268"
        width="711.1879"
        data-name="Layer 1"
        id="b52d7e2d-d80f-4111-b6ed-d15502ee1edd"
      >
        <path
          d="M 162,94.8 C 129.81195,93.8403 99.15574,84.930003 69.877152,75.179436 40.59857,65.42887 11.608446,54.624763 -19.808717,49.459437 c -20.207038,-3.3227 -43.313601,-3.791886 -59.59661,5.498023 -15.666306,8.957214 -20.729253,24.376417 -23.454693,38.69517 -2.04408,10.7785 -3.25262,22.11579 2.36238,32.20332 3.894189,7.00368 10.812228,12.88984 15.596681,19.59923 16.641093,23.33993 4.878933,52.12244 -13.159698,74.91214 -8.454833,10.68896 -18.272383,20.90016 -24.802493,32.27157 -6.53012,11.3714 -9.54899,24.41907 -3.83949,36.02932 5.66474,11.51642 19.15765,20.14948 33.769589,26.22758 29.686403,12.3439 64.65459,15.87985 98.7822629,17.88031 75.5066201,4.43168 151.4210581,2.51228 227.1265981,0.59287 28.02032,-0.71231 56.15998,-1.43315 83.72273,-5.14826 15.30822,-2.06443 31.11377,-5.3402 42.22441,-13.22255 14.10463,-10.03634 17.60095,-27.03373 8.15146,-39.61648 -15.8553,-21.10917 -59.68116,-26.35553 -70.77689,-49.00875 -6.1024,-12.46759 0.16413,-26.3598 9.02677,-37.92314 19.01841,-24.81147 50.89312,-46.57324 52.57414,-74.93347 C 359.05227,94.04077 343.72913,74.535369 320.0357,65.31797 295.1984,55.656976 260.76237,56.872597 242.4502,72.863357 223.57106,89.31051 190.41817,95.64454 162,94.8 Z"
          fill={theme.accentColor}
          opacity="0.15"
          id="path13"
        />
      </svg>
      <img
        style={{
          position: "absolute",
          right: 0,
          left: 0,
          top: 130,
          margin: "0 auto",
        }}
        src="me.png"
        alt=""
        width="230px"
        height="320px"
      />
    </div>
  );
}

export default FeelingProud;
