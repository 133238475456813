import React, { useMemo } from "react";
import ProjectLanguages from "../projectLanguages/ProjectLanguages";
import Gallery2 from "./Gallery2";
import { style } from "glamor";

import "./ProjectCard.css";
import "react-responsive-modal/styles.css";

const imageDetails = [
  {
    index: 0,
    src: "buld.jpg",
    width: "1024",
    height: "568",
  },
  // {
  //   index: 0,
  //   src: 'prosale-products.png',
  //   width: '800',
  //   height: '1000',
  // },
  // {
  //   index: 0,
  //   src: 'prosale-register.png',
  //   width: '1024',
  //   height: '768',
  // },
  {
    index: 1,
    src: "prosale-home.png",
    width: "350",
    height: "768",
  },
  {
    index: 1,
    src: "prosale-products.png",
    width: "800",
    height: "1000",
  },
  {
    index: 1,
    src: "prosale-main.png",
    width: "1024",
    height: "768",
  },
  {
    index: 1,
    src: "prosale-register.png",
    width: "1024",
    height: "768",
  },
  {
    index: 1,
    src: "prosale-account.png",
    width: "1024",
    height: "768",
  },
  {
    index: 2,
    src: "pixi-home.png",
    width: "250",
    height: "1220",
  },
  {
    index: 2,
    src: "pixi-products.png",
    width: "450",
    height: "1124",
  },
  {
    index: 2,
    src: "pixi-cart.png",
    width: "800",
    height: "840",
  },
  {
    index: 2,
    src: "pixi-checkout.png",
    width: "1000",
    height: "820",
  },
  {
    index: 2,
    src: "pixi-orders.png",
    width: "800",
    height: "690",
  },

  {
    index: 2,
    src: "pixi-profile.png",
    width: "1000",
    height: "520",
  },
  {
    index: 3,
    src: "buld.jpg",
    width: "1024",
    height: "568",
  },
  {
    index: 4,
    src: "ordro-home.png",
    width: "1200",
    height: "1024",
  },
  {
    index: 4,
    src: "ordro-orders.png",
    width: "1200",
    height: "1024",
  },
  {
    index: 4,
    src: "ordro-branches.png",
    width: "1000",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-home.png",
    width: "1600",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-students.png",
    width: "1800",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-charts.png",
    width: "1000",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-stories.png",
    width: "900",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-mobile-home.png",
    width: "500",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-nots.png",
    width: "500",
    height: "1024",
  },
  {
    index: 5,
    src: "alsabbagh-register.png",
    width: "500",
    height: "1024",
  },
  {
    index: 6,
    src: "buld.jpg",
    width: "1024",
    height: "568",
  },
  {
    index: 7,
    src: "spantrek-1.png",
    width: "1124",
    height: "700",
  },
  {
    index: 7,
    src: "spantrek-2.png",
    width: "1124",
    height: "700",
  },
  {
    index: 7,
    src: "spantrek-3.png",
    width: "1124",
    height: "700",
  },
  {
    index: 7,
    src: "spantrek-4.png",
    width: "1124",
    height: "700",
  },
  {
    index: 7,
    src: "spantrek-5.png",
    width: "1124",
    height: "700",
  },
  {
    index: 7,
    src: "spantrek-6.png",
    width: "1124",
    height: "700",
  },
];

export default function ProjectCard({
  repo,
  theme,
  onClick,
  isOpen,
  selectedCard,
}) {
  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    padding: "1.5rem",
    cursor: "default",
    borderRadius: "5px",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
  });

  const GitLabButton = style({
    textDecoration: "none",
    color: "rgba(255, 255, 255, 1)",
    background: `${theme.accentColor}`,
    padding: "15px 15px",
    marginTop: "25px",
    borderRadius: "4px",
    borderWidth: "0px",
    width: "200px",
    height: "50px",
    fontWeight: "bold",
    fontFamily: "Google Sans Regular",
    fontSize: "13px",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    ":hover": {
      color: "rgba(255, 255, 255, 1)",
      boxShadow: `0 5px 10px ${theme.accentColor}`,
    },
  });

  const ScreenshotsButton = style({
    textDecoration: "none",
    color: "#fff",
    background: "transparent",
    padding: "15px 15px",
    borderRadius: "4px",
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    height: "50px",
    fontWeight: "bold",
    fontFamily: "Google Sans Regular",
    fontSize: "13px",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    border: `1px solid ${theme.accentColor}`,
    ":hover": {
      boxShadow: `0 5px 10px ${theme.accentColor}`,
    },
  });

  const _images = useMemo(() => {
    return imageDetails.filter((image) => image.index === selectedCard);
  }, [selectedCard]);

  return (
    <>
      {/* <Modal open={isOpen} onClose={() => onClick(-1)}> */}
      {isOpen && (
        <div
          style={{
            padding: "55px 0",
            position: "fixed",
            inset: 0,
            zIndex: 999,
          }}
        >
          <Gallery2
            photos={_images.map((item) => ({
              src: item.src,
              w: item.width,
              h: item.height,
            }))}
            onClose={() => onClick(-1)}
          />
        </div>
      )}
      {/* </Modal> */}

      <div>
        <div
          {...styles}
          key={repo.id}
          // onClick={() => openRepoinNewTab(repo.url)}
          style={{
            backgroundColor: theme.projectCard,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="repo-name-div">
              <p className="repo-name" style={{ color: theme.text }}>
                {repo.name}
              </p>
            </div>
            <p className="repo-description" style={{ color: theme.text }}>
              {repo.description}
            </p>
            <div className="repo-details">
              <ProjectLanguages logos={repo.languages} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ color: "gray", fontWeight: "bold", textAlign: "end" }}>
              {repo.date}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "0 5px",
                alignItems: "center",
              }}
            >
              {repo.repo && (
                <a {...ScreenshotsButton} href={repo.repo} target="_blank">
                  Gitlab Repo
                </a>
              )}

              {!repo.noScreen && (
                <button {...GitLabButton} onClick={() => onClick()}>
                  Screenshots
                </button>
              )}

              {repo.website && (
                <a {...ScreenshotsButton} href={repo.website} target="_blank">
                  Online
                </a>
              )}
              {repo.mobile && (
                <a {...ScreenshotsButton} href={repo.mobile} target="_blank">
                  Mobile
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
