import { PhotoSwipe } from 'react-photoswipe';

import 'react-photoswipe/lib/photoswipe.css';
import './ProjectCard.css';

import React from 'react';

export default function Gallery2({ photos, onClose }) {
  return (
    <PhotoSwipe
      isOpen
      items={photos}
      onClose={onClose}
      className="z-999"
    />
  );
}
